import { env } from 'utils/env';

const DESKIE_KEY = env.REACT_APP_DESKIE_KEY;
const DESKIE_TYPE = env.REACT_APP_DESKIE_TYPE ?? 'messengers_mail';

const widgetScript = (key: string) => `
!function(e, o) {
    !window.deskie ? window.deskie = [] : '';
    window.deskie.push(o);
    o.g_config = {
        widget_id: "${key}"
    };
    o.type_widget = "${DESKIE_TYPE}";
    o.email_widget = o.email_widget || {};
    var w = o.email_widget;
    w.readyQueue = [];
    o.config = function(e) {
        this.g_config.user = e
    };
    w.ready = function(e) {
        this.readyQueue.push(e)
    };
    var r = e.getElementsByTagName("script")[0];
    c = e.createElement("script");
    c.type = "text/javascript", c.async = !0;
    c.src = "https://deskie.io/bundles/acmesite/js/cwidget0.2.min.js";
    r.parentNode.insertBefore(c, r)
}(document, []);
`;

export const deskieWidget = {
  key: DESKIE_KEY,
  add: function () {
    if (this.key == null) return;

    const script = document.createElement('script');
    script.id = `script_${this.key}`;
    script.async = true;
    script.innerHTML = widgetScript(this.key);
    document.head.appendChild(script);
  },
  remove: function () {
    const elements = [document.getElementById(`script_${this.key}`)];

    elements.forEach((element) => {
      if (element != null) {
        element.remove();
      }
    });
  },
};

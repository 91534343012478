import QRCodeStyling from 'qr-code-styling';

export const QR_SIZE = 500;

export const qrCode = new QRCodeStyling({
  width: QR_SIZE,
  height: QR_SIZE,
  dotsOptions: {
    type: 'extra-rounded',
  },
  backgroundOptions: {
    color: 'rgb(251, 252, 253)',
  },
});
